import React, { useState } from "react";
import { CustomButton } from "../custom-components/customButtons";
import Link from "next/link";
export const NoWalletPopUp = ({ show, setShow } : any) => {
  const [isLoad] = useState<boolean>(false);

  return (
    <>
      { show && <div id="stardust-modal" className="modal" onClick={() => setShow(!show)}>
        <div className="modal-content">
          <div className="modal-body">
            <div className="close" onClick={() => setShow(!show)}>
              <i className="fas fa-times"></i>
            </div>
            <div className="at-modalcontentarea">
              <p style={{ textAlign: "center", marginBottom: 15 }}>
                Para hacer compras, primero debes añadir una tarjeta
              </p>
              <Link href="/profile?tab=7" passHref={true}>
                <CustomButton
                  label={"Añadir tarjeta"}
                  type="button"
                  loading={isLoad}
                  class={"button primary"}
                  onClick={() => setShow(!show)}
                />
              </Link>
            </div>
          </div>
        </div>
      </div>}
      <style jsx>{`
        .modal {
          display: flex;
          position: fixed;
          z-index: 10;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          overflow: auto;
          background-color: rgb(0, 0, 0);
          background-color: rgba(0, 0, 0, 0.4);
        }

        .at-modalcontentarea {
          display: flex;
          flex-direction: column;
        }

        .modal-header {
          padding: 2px 16px;
          background-color: #5cb85c;
          color: white;
        }

        .modal-body {
          padding: 2px 16px;
          position: relative;
          padding-top: 15px;

          .close {
            position: absolute;
            right: 7px;
            top: -10px;
            font-size: 14px;
            color: #a5ddfe;
            cursor: pointer;
          }
        }

        .modal-footer {
          padding: 2px 16px;
          background-color: #5cb85c;
          color: white;
        }

        .modal-content {
          position: relative;
          background-color: #191D32;
          margin: auto;
          padding: 0;
          border-radius: 8px;
          width: 80%;
          max-width: 400px;
          padding: 25px 10px;
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
            0 6px 20px 0 rgba(0, 0, 0, 0.19);
          animation-name: animatetop;
          animation-duration: 0.4s;

          .price-box {
            margin: 0 auto;
            width: 100%;
            height: 60px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: var(--primary-font);
            font-weight: 700;
            background: linear-gradient(45deg, #d7c9ff, #9cdcfc);
            -webkit-font-smoothing: antialiased;
            margin-bottom: 20px;
            color: white;

            span {
              font-size: 1.8rem;
              text-shadow: 1px 1px 2px #0000001a;
            }
          }

          .grid {
            display: grid;
            margin-bottom: 10px;

            &.col-1 {
              grid-template-columns: 1fr;
            }

            &.col-2 {
              grid-template-columns: 1fr 1fr;
              gap: 10px;
            }
          }
        }

        @keyframes animatetop {
          from {
            top: -300px;
            opacity: 0;
          }
          to {
            top: 0;
            opacity: 1;
          }
        }
        .button {
          border-radius: 8px;
          -webkit-font-smoothing: antialiased;
          font-weight: 700;
          font-size: 16px;
          letter-spacing: 0.2px;
          margin: 0 auto;
          display: block;
        }
      `}</style>
    </>
  );
};
