import {useCallback, useEffect, useReducer, useState} from "react";
import useTranslation from "next-translate/useTranslation";
import _get from "lodash/get";
import _debounce from "lodash/debounce";
import _compact from "lodash/compact";
import {useRouter} from "next/router";

const initialState = {
  content: [],
  meta: {
    total: 0,
    next: true,
    page: 0,
  },
};

const reducer = (state: any, {action, values}: any) => {
  switch (action) {
    case "reset":
      return initialState;
    case "update":
      return Object.assign({}, state, values);
    default:
      return state;
  }
};

export const usePagination = (
  service: Function,
  name: string,
  args: any,
  {emptyMessage, widthAdverts = true, searchService}: any
) => {
  const {t} = useTranslation("common");
  const router = useRouter();
  const [{content, meta}, dispatch] = useReducer(reducer, initialState);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [filter, setFilter] = useState("");
  const search = _get(router, "query.q", "");
  const next = _get(meta, "next", false);
  const page = _get(meta, "page", 0);
  const total = _get(meta, "total", 0);
  const id = _get(args, "id", null);

  const getArgs = useCallback(() => {
    if (filter) {
      return {...args, filter};
    } else {
      if (search) {
        return search;
      } else {
        return args;
      }
    }
  }, [args, filter, search]);

  const loadContent = _debounce(async () => {
    setLoading(true);
    const currentService = searchService && search ? searchService : service;
    const {data} = await currentService(getArgs(), parseInt(page) + 1);

    const items = [
      ...content,
      ..._get(data, name, [])
    ]

    if (widthAdverts) {

      if (((items.length - (Math.floor(items.length / 12))) % 12) > 8) {

        items.splice(((items.length - 12) + 2), 0, {"type": "advert"})
        if (items.length > 10) {

          items.splice(((items.length - 12) + 9), 0, {"type": "advert"})

        } else if (items.length > 7) {

          items.splice(((items.length - 12) + 4), 0, {"type": "advert"})

        }

      }

    }

    dispatch({
      action: "update",
      values: {
        content: _compact(items),
        meta: data.meta,
      },
    });
    setLoading(false);
  }, 1000);

  const nextPage = useCallback(async () => {
    try {
      if (!next || loading) return;
      await loadContent();
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }, [loadContent, loading, next]);

  const reload = () => {
    dispatch({action: "reset"});
    setError(false);
  };

  useEffect(() => {
    if (total === 0 && parseInt(page) === 1 && !loading && !content.length) {
      setError(emptyMessage || t("messages.noVideos"));
    }
    // eslint-disable-next-line
  }, [loading, content, meta, total, page]);

  useEffect(() => {
    setLoading(false);
    nextPage();
    // eslint-disable-next-line
  }, [page]);

  useEffect(() => {
    reload();
    // eslint-disable-next-line
  }, [filter, search, id]);

  return [
    content,
    {
      loading: loading,
      error: error,
      next: next,
      page: page,
      total: total,
      meta: meta,
      nextPage,
      reload,
      setFilter,
    },
  ];
};
