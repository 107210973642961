import React, {useState, useRef, useEffect} from "react";
import _get from 'lodash/get'
import { Link } from "@material-ui/core";
import { getDurationString } from "@utils/getDurationString.utils";
import { useCards } from "@hooks/useCards";
import { NoWalletPopUp } from "@components/common/noWalletPopup.component";
import { CustomButton } from "@components/custom-components/customButtons";
import useTranslation from "next-translate/useTranslation";
import { SimpleModal } from "@components/common/simpleModal.component";
import { useSubscription } from "@hooks/useSubscription";
import router from "next/router";
import {notify} from "@utils/toast.utils";

export const VideoCardComponent = ({ video, user, streamDuration = {} }: any) => {
  const { t } = useTranslation("common")
  const [showModal, setShowModal] = useState<boolean>(false);
  const [confirmation, setConfirmation] = useState<boolean>(false)
  const [subscribed, { getChannelSubscription, subscribe }] = useSubscription();
  const [{ card }] = useCards();
  const [time, setTime] = useState<number>(0);

  const placeholder = "/assets/images/stardeos02.png";

  useEffect(() => {
      if (user && video.channelId?.id) {
          getChannelSubscription(video.channelId?.id, user._id);
      }
   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, video.channelId]);

  useEffect(() => {
    if (video.id in streamDuration) {
      setTime(streamDuration[video.id])
    }
  }, [streamDuration])

  useEffect(() => {
    const interval = setInterval(() => {
      setTime((p) => p <= 0 ? 0 : p - 1);
    }, 1000)
    return () => clearInterval(interval)
  }, [time]);

  function VideoComponent() {
    const imageEl = useRef(null);
    return (
      <a>
        <span className="at-tiem" id={"duration-" + video._id}>{ time ? getDurationString(time) : getDurationString(video.duration)}</span>
        <div className="image-16-9">
          <img
            style={{width: '100%', height: !video.thumbnail ? 100 : "initial", borderRadius: !video.thumbnail ? "100%" : "initial",  margin: !video.thumbnail ? "30px auto" : "initial", objectFit: "cover"}}
            src={video.thumbnail ? video.thumbnail : video.creator?.avatar}
            alt={video.title}
            onError={() => {
              // @ts-ignore
              imageEl.current.src = placeholder;
            }}
            ref={imageEl}
          />
        </div>
      </a>
    );
  }

  return (
    <React.Fragment>
      <div className="at-video">
        {(user && video.subscribersOnly && !subscribed?.subscribed) && (
          <>
            <NoWalletPopUp show={showModal} setShow={setShowModal} />
            <Link onClick={() => setShowModal(true)}>
              <VideoComponent />
            </Link>
          </>
        )}
        {(user && video.subscribersOnly && !subscribed?.subscribed  && (user.id !== video.creator.id) ) && (
          <>
            <div className="at-overlay">
              <CustomButton
                label={t("videoCard.subscribe")}
                loading={false}
                class={"button secondary"}
                type="button"
                onClick={() => {
                  if (!card) {

                    notify("warning", "Debes añadir una tarjeta para suscribirte");
                    router.push("/profile?tab=7")
                  }
                  setConfirmation(true)
                }}
              />
            </div>
            <VideoComponent />
          </>
        )}
        {(user && video.subscribersOnly && subscribed?.subscribed) && (
           <Link href={video.isLiveStream ? `/live/${video.creator?.username}` : `/video/${video.id}`}>
             <VideoComponent />
           </Link>
        )}
        {!user && video.subscribersOnly && (
          <>
              <div className="at-overlay">
                  <CustomButton
                      label={t("videoCard.subscribe")}
                      loading={false}
                      class={"button secondary"}
                      type="button"
                      onClick={() => router.push("/login")}
                  />
              </div>
              <VideoComponent />
          </>
        )}
        {!video.subscribersOnly && (
          <Link href={video.isLiveStream ? `/live/${video.creator?.username}` : `/video/${video.id}`}>
            <VideoComponent />
          </Link>
        )}
        {user && confirmation && _get(card, "id", false) && (
          <SimpleModal
            label="submit"
            onClick={() => subscribe(video.channelId.id)}
            title={t("banner.subscribe")}
            default={true}
            toggle={() => { setConfirmation(!confirmation) }}
            text={
              t("subscribeModal.description") +
              " " +
                video.channelId?.price +
              " EUR " +
              t("subscribeModal.period")
            }
          // data={unSubs}
          />
        )}
      </div>
      <style jsx>{`
        .at-video {
          position: relative;

          @media screen and (max-width: 600px) {
            margin-bottom: 5px;
          }
        }
        .image-16-9 {
          width: 100%;
          padding-top: 56.25%;
          height: 0;
          position: relative;
          margin-bottom: 20px;

          @media screen and (max-width: 600px) {
            margin-bottom: 5px;
          }

          img {
            width: 100%;
            height: 100%;
            position: absolute;
            object-fit: cover;
            top: 0;
            left: 0;
          }
        }

        .at-tiem {
          z-index: 2;
        }

        .at-overlay {
          display: flex;
          height: 100% !important;

          button {
            height: 40px !important;
            flex: initial;
          }
        }
      `}</style>
    </React.Fragment>
  );
};
