import React, { createContext, ReactNode, useState, useEffect, useContext, FC } from "react";
import { useController } from "react-hook-form";

type State = {
    channel: any;
    disabled: boolean;
    setDisabled: (disabled: boolean) => void;
}

const ModalContext = createContext<State>({} as State);

type ModalProviderProps = {
    disabled?: boolean;
    channel?: any;
}

export const ModalProvider:FC<ModalProviderProps> = ({ children, channel, disabled:disabledBase }) => {

    const [ disabled, setDisabled ] = useState(disabledBase ?? false);

    return <ModalContext.Provider value={{ disabled, channel, setDisabled }}>
        {children}
    </ModalContext.Provider>   
}

export const useModal = () => { 
    const context = useContext(ModalContext);

    if(!context) throw new Error("Need a modal provider");
    return context;
};