import React, { useRef } from "react";

import { Link } from "@material-ui/core";
import { getDurationString } from "@utils/getDurationString.utils";
import useTranslation from "next-translate/useTranslation";
import { videoService } from "@services/video";
import { notify } from "@utils/toast.utils";
declare const window: any;

export const VideoCardTwoComponent = ({ video }: any) => {
  const { t } = useTranslation("common");

  const placeholder = "/assets/images/stardeos02.png";

  const deleteVideo = async (id: string) => {
    try {
      await videoService.deleteVideo(id);
      //notify("success", "Video eliminado", { theme: "colored" });
      window.location.reload();
    } catch (err) {
      console.log(err);
      notify("error", "El video no puede ser eliminado", { theme: "colored" });
    }
  };

  function VideoComponent() {
    const imageEl = useRef(null);

    if (video?.waitTranscoding) {
      return (
        <div className="at-video">
          <span className="at-tiem">{getDurationString(video.duration)}</span>

          <img
            src={video.thumbnail}
            alt={video.title}
            onError={() => {
              // @ts-ignore
              imageEl.current.src = placeholder;
            }}
            ref={imageEl}
          />

          <div className="bg-text">
            <p>Procesando...</p>
          </div>
        </div>
      );
    } else {
      return (
        <div className="at-video">
          <span className="at-tiem">{getDurationString(video.duration)}</span>

          <img
            src={video.thumbnail}
            alt={video.title}
            onError={() => {
              // @ts-ignore
              imageEl.current.src = placeholder;
            }}
            ref={imageEl}
          />
        </div>
      );
    }
  }

  return (
    <React.Fragment>
      <div className="at-videobox" key={video._id}>
        <VideoComponent />

        <div className="at-videocontent">
          <h4> {video.title} </h4>

          <div className="at-description">
            <p dangerouslySetInnerHTML={{ __html: video.description }} />
          </div>

          <div className="at-actions">
            <Link href={`/edit-video/${video.id}`}>
              <i className="icon-edit"></i> {t("yourVideos.editVideo")}
            </Link>
            <a href="javascript:void(0)" onClick={() => deleteVideo(video.id)}>
              <i className="icon-delete"></i> {t("yourVideos.deleteVideo")}
            </a>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
